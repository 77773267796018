import React from "react";
import {
  EHSContainer,
  BannerContainer,
  LeftSection,
  RightSection,
  Title,
  Description,
  TagsContainer,
  Image,
} from "./Elements";
import { ehs } from "utils/assets";
import Tag from "components/custom/Tag";
const EHS = () => {
  return (
    <EHSContainer className="container" id="ehs">
      <BannerContainer>
        <LeftSection>
          <Title>ethicallyHackingspace (eHs)™</Title>
          <Description>
          Shielding commercial space operations' digital landscape against emerging cybersecurity threats is increasingly difficult. In addition to an already complex mission challenged by natural threats like space debris, evolving threats like anti-satellite weapons, and the intrinsic threat of operational component failures. To survive, organizations and the consumers of their services must understand and prepare for the possible impact of emerging cybersecurity threats. The ethicallyHackingspace (eHs)™ community project implements a global attack surface management methodology designed to significantly reduce the risk of emerging space threats while building an international community of certified Space Cybersecurity Professionals (SCP).
          </Description>
          <TagsContainer>
            <Tag title="Launch Segment" childs={["launch"]} />
            <Tag title="Space Segment" childs={["spacecraft"]} />
            <Tag title="Ground Segment" childs={["ground station"]} />
            <Tag title="User Segment" childs={["consumers"]} />
            <Tag title="Support Segment" childs={["ground support"]} />
          </TagsContainer>
        </LeftSection>
        <RightSection>
          <Image src={ehs} alt="Ehs Structure" />
        </RightSection>
      </BannerContainer>
    </EHSContainer>
  );
};

export default EHS;
