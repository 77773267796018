import React from "react";
import {
  HeroContainer,
  HeroTitle,
  HeroDescription,
  CTAButton,
} from "./Elements";
const HeroSection = () => {
  return (
    <HeroContainer id="home" className="container">
      <HeroTitle>Project</HeroTitle>
      <HeroDescription>
      ethicallyHackingspace (eHs)™ is a Hightech Humanity (H2)® non-profit project that provides free technical certification for candidates that want to become Space Cybersecurity Professionals (SCP). If you have a passion for Cybersecurity and Space, you are in the right place.
      </HeroDescription>
      <CTAButton to="ehs" smooth offset={-150}>
        ethicallyHackingspace(eHs)™
      </CTAButton>
    </HeroContainer>
  );
};

export default HeroSection;
