import React from "react";
import { FooterContainer, FooterLogo, CopyRight } from "./Elements";
const Footer = () => {
  return (
    <FooterContainer className="container">
      <FooterLogo>ethicallyHackingspace (eHs)™</FooterLogo>
      <CopyRight>© 2023 All Right Reserved</CopyRight>
    </FooterContainer>
  );
};

export default Footer;
