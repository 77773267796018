import React, { useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!isOpen);
  };
  return (
    <header>
      <Navbar isOpen={isOpen} toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
    </header>
  );
};

export default Header;
