import styled from "styled-components";

export const DonationContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20vh;
  flex-direction: column;
`;
export const SectionDetail = styled.p`
  color: ${(props) => props.theme.tertiary};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
`;

export const DonateContainer = styled.div`
  height: 100%;
  width: 80%;
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5vh;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
