import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";

export const NavbarContainer = styled.div`
  height: ${(props) => (props.$scrollNav ? "70px" : "80px")};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: ${(props) =>
    props.$scrollNav ? props.theme.secondary : "transparent"};
  z-index: 10;
  transition: all 0.3s ease;
`;

export const NavLogo = styled.h3`
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: ${(props) => props.theme.tertiary};
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
`;

export const NavItems = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const NavLinks = styled(ScrollLink).attrs(() => ({
  activeClass: "active",
}))`
  color: ${(props) => props.theme.tertiary};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Josefin Sans";
  font-style: normal;
  transition: all 0.2s ease-in;
  height: 100%;
  cursor: pointer;
  width: 100%;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: -50%;
    z-index: -1;
    opacity: 0;
    border-radius: 10px;
    background-color: ${(props) => props.theme.tertiary};
    transition: 0.3s;
  }
  &:hover {
    &::after {
      width: 20%;
      opacity: 1;
    }
  }
  &.active {
    &::after {
      width: 50%;
      opacity: 1;
    }
  }
`;
