import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 4vw;
  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 4vh;
  }
`;

const Title = styled.h4`
  color: ${props=>props.theme.tertiary};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin-bottom: 2vh;
  white-space: nowrap;
`;

const Childs = styled.li`
  text-decoration: none;
  list-style: none;
  font-size: 1rem;
  text-transform: capitalize;
  color: ${(props) => props.theme.tertiary};
  font-family: "Poppins";
`;

const Tag = ({ title, childs }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <ul>
        {childs?.map((child, i) => {
          return <Childs key={`${child}-${i}`}>{child}</Childs>;
        })}
      </ul>
    </Container>
  );
};

export default Tag;
