import React, { useState, useEffect } from "react";
import {
  NavbarContainer,
  NavLogo,
  NavItems,
  NavLinks,
  MobileIcon,
} from "./Elements";
import { Divide as Hamburger } from "hamburger-react";
import { theme } from "theme";
const Navbar = ({ isOpen, toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 30) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <NavbarContainer $scrollNav={scrollNav} className="container">
      <NavLogo>ethicallyHackingspace (eHs)™</NavLogo>
      <MobileIcon>
        <Hamburger
          size={20}
          color={theme.tertiary}
          toggled={isOpen}
          toggle={toggle}
        />
      </MobileIcon>
      <NavItems>
        <NavLinks
          to="home"
          spy={true}
          activeClass="active"
          smooth
          offset={-100}
        >
          Project
        </NavLinks>
        <NavLinks
          to="community"
          spy={true}
          activeClass="active"
          smooth
          offset={-150}
        >
          Community
        </NavLinks>
        <NavLinks
          to="certification"
          spy={true}
          activeClass="active"
          smooth
          offset={-200}
        >
          Certification
        </NavLinks>
        <NavLinks
          to="donation"
          spy={true}
          activeClass="active"
          smooth
          offset={-225}
        >
          Donation
        </NavLinks>
      </NavItems>
    </NavbarContainer>
  );
};

export default Navbar;
