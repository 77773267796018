import React from "react";
import HeroSection from "components/layout/HeroSection";
import CommunitySection from "components/layout/CommunitySection";
import Donation from "components/layout/Donation";
import Certification from "components/layout/Certification";
import EHS from "components/layout/EHS";
import ScrollToTop from "components/custom/ScrollToTop";
const Landing = () => {
  return (
    <>
      <HeroSection />
      <EHS />
      <CommunitySection />
      <Certification />
      <Donation />
      <ScrollToTop />
    </>
  );
};

export default Landing;
