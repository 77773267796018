import React from "react";
import Landing from "pages/landing";
import Header from "components/layout/Header";
import Footer from "components/layout/Footer";
import { ThemeProvider } from "styled-components";
import { theme } from "theme";
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Landing />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
