import React from "react";
import SectionTitle from "components/custom/SectionTitle";
import {
  CommunityContainer,
  DetailsContainer,
  LeftSection,
  Detail,
  RightSection,
  Image,
} from "./Elements";
import { community } from "utils/assets";
const CommunitySection = () => {
  return (
    <CommunityContainer className='container' id='community'>
      <SectionTitle title='Community' />
      <DetailsContainer>
        <LeftSection>
          <Detail>
            Our inclusive global community is focused on providing free training and space cybersecurity resilience resources for the commercial space industry. Are you already proficient or interested in learning the skills you need to create space cybersecurity professional threat models, use cases, playbooks, and other resilience artifacts to share with the commercial space industry? Do you want to master applying the MITRE Common Attack Pattern Enumeration and Classification to create advanced space threat detection use cases? If so, you are in the right place.
            If you interested in joining the project please book a session with
            our{" "}
            <a
              href='https://calendly.com/na_hightechhumanity/join-a-hightech-humanity-h2-community-project'
              target='_blank'
              style={{ color: "#fff" }}
              rel='noreferrer'>
              US
            </a>{" "}
            or{" "}
            <a
              href=' https://calendly.com/eu_hightechhumanity/join-a-hightech-humanity-h2-community-project'
              target='_blank'
              style={{ color: "#fff" }}
              rel='noreferrer'>
              EU
            </a>{" "}
            onboarding teams.
          </Detail>
        </LeftSection>
        <RightSection>
          <Image src={community} alt='Community Image' />
        </RightSection>
      </DetailsContainer>
    </CommunityContainer>
  );
};

export default CommunitySection;
