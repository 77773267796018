import React from "react";
import { SidebarContainer,NavLinks } from "./Elements";
const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      <NavLinks to="home" spy={true} activeClass="active" smooth offset={-100} onClick={toggle}>
        Project
      </NavLinks>
      <NavLinks
        to="community"
        spy={true}
        activeClass="active"
        smooth
        offset={-150}
        onClick={toggle}
      >
        Community
      </NavLinks>
      <NavLinks
        to="certification"
        spy={true}
        activeClass="active"
        smooth
        offset={-200}
        onClick={toggle}
      >
        Certification
      </NavLinks>
      <NavLinks
        to="donation"
        spy={true}
        activeClass="active"
        smooth
        offset={-225}
        onClick={toggle}
      >
        Donation
      </NavLinks>
    </SidebarContainer>
  );
};

export default Sidebar;
