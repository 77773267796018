import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.275;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #008cff;
  border-radius: 10px;
  padding: 1rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 4vh;
  }
`;
const Image = styled.img`
  width: 70%;
  height: auto;
  margin-bottom: 2vh;
`;
const Title = styled.h4`
  color: ${(props) => props.theme.tertiary};
  margin-bottom: 2vh;
  white-space: nowrap;
`;
const Btn = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.tertiary};
  background-color: #009ee3;
  padding: 0.75rem;
  width: 70%;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
`;

const DonationCard = ({ title, img, href }) => {
  return (
    <CardContainer>
      <Image src={img} alt="image" />
      <Title>{title}</Title>
      <Btn href={href} target="_blank" referrerPolicy="no-referrer">
        Donate
      </Btn>
    </CardContainer>
  );
};

export default DonationCard;
