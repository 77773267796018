import React from "react";
import {
  CertificationContainer,
  CertificationsListContainer,
} from "./Elements";
import SectionTitle from "components/custom/SectionTitle";
import CertificationCard from "components/custom/CertificationCard";
import {
  defensive,
  offensive,
  purple,
  spectrum,
  OffensiveSSP,
  DefensiveSSP,
  PurpleTeamSSP,
  FullSpectrumSSP,
} from "utils/assets";
const Certification = () => {
  const CERTIFICATIONS = [
    {
      title: "Offensive SCP",
      image: offensive,
      color: "#E94F37",
      file: OffensiveSSP,
    },
    {
      title: "Defensive SCP",
      image: defensive,
      color: "#4281A4",
      file: DefensiveSSP,
    },
    {
      title: "Purple Team SCP",
      image: purple,
      color: "#682D63",
      file: PurpleTeamSSP,
    },
    {
      title: "Full Spectrum SCP",
      image: spectrum,
      color: "#9AB87A",
      wd: "47%",
      file: FullSpectrumSSP,
    },
  ];
  return (
    <CertificationContainer className="container" id="certification">
      <SectionTitle title="Space Cybersecurity Professional (SCP) Certification" />
      <CertificationsListContainer>
        {CERTIFICATIONS?.map((certification, i) => {
          return (
            <CertificationCard
              key={`${certification.title}-${i}`}
              title={certification.title}
              img={certification.image}
              color={certification.color}
              wd={certification?.wd}
              //file={certification.file}
            />
          );
        })}
      </CertificationsListContainer>
    </CertificationContainer>
  );
};

export default Certification;
