import React, { useState } from "react";
import { BsChevronUp } from "react-icons/bs";

import styled from "styled-components";
import { theme } from "theme";

export const Heading = styled.h1`
  text-align: center;
  color: green;
`;

export const Content = styled.div`
  overflow-y: scroll;
  height: 2500px;
`;

export const Button = styled.div`
  position: fixed;
  width: 100%;
  left: 95%;
  bottom: 40px;
  height: 50px;
  width: 50px;
  font-size: 3rem;
  border-radius: 10px;
  display: ${(props) => (props.$visible ? "flex" : "none")};
  transition: all 0.2s ease-in;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  background-color: ${(props) => props.theme.tertiary};

  @media screen and (max-width: 768px) {
    left: 90%;
  }
  @media screen and (max-width: 480px) {
    left: 80%;
  }
`;

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      <Button onClick={scrollToTop} $visible={visible}>
        <BsChevronUp size={20} color={theme.other} />
      </Button>
    </>
  );
};

export default ScrollToTop;
