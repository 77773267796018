import React from "react";
import { DonationContainer, SectionDetail, DonateContainer } from "./Elements";
import { paypal, venmo } from "utils/assets";
import SectionTitle from "components/custom/SectionTitle";
import DonationCard from "components/custom/DonationCard";
const Donation = () => {
  const DONATION_RESOURCES = [
    {
      title: "For European Union",
      href: "https://www.paypal.com/donate/?hosted_button_id=YVLU56GFVGD74",
      image: paypal,
    },
    {
      title: "For United States",
      href: "https://www.paypal.com/us/fundraiser/charity/4270671",
      image: paypal,
    },
    {
      title: "For United States",
      href: "https://account.venmo.com/u/d2teamcorp",
      image: venmo,
    },
  ];

  return (
    <DonationContainer className="container" id="donation">
      <SectionTitle title="Donation" />
      <SectionDetail>
        Our non-profit teams need your support to continue developing projects so please consider a $1.00 USD or €1.00 Euro donation.
      </SectionDetail>
      <DonateContainer>
        {DONATION_RESOURCES?.map((resource, i) => {
          return (
            <DonationCard
              title={resource.title}
              href={resource.href}
              img={resource.image}
              key={`${resource.title}-${i}`}
            />
          );
        })}
      </DonateContainer>
    </DonationContainer>
  );
};

export default Donation;
