import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.24;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 2rem;
  flex-direction: column;
  background-color: ${(props) => props.theme.other};
  border-radius: 10px;
  border: 1px solid ${(props) => props.$color};

  @media screen and (max-width: 768px) {
    margin-bottom: 3vh;
  }
`;

const Title = styled.h4`
  background-color: ${(props) => props.theme.tertiary};
  padding: 5% 10%;
  margin-bottom: 5vh;
  border-radius: 10px;
  color: ${(props) => props.$color};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
`;
const Image = styled.img`
  width: ${(props) => props.$wd || "40%"};
  margin-bottom: 5vh;
`;
const Btn = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.tertiary};
  background-color: ${(props) => props.theme.secondary};
  padding: 5% 10%;
  border-radius: 10px;
`;
const CertificationCard = ({ title, img, color, wd, file }) => {
  return (
    <Container $color={color}>
      <Title $color={color}>{title}</Title>
      <Image src={img} alt="Certification image" $wd={wd} />
      <Btn href={file} download>
        Learn More
      </Btn>
    </Container>
  );
};

export default CertificationCard;
