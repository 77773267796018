import styled from "styled-components";

export const FooterContainer = styled.div`
  min-height: 8vh;
  height: 10vh;
  max-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.other};
`;

export const FooterLogo = styled.h3`
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: ${(props) => props.theme.tertiary};
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 1rem;
    line-height: 1rem;
  }
`;

export const CopyRight = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: ${(props) => props.theme.tertiary};
  @media screen and (max-width: 480px) {
    font-size: 0.6rem;
    line-height: 0.6rem;
  }
`;
