import React from "react";
import styled from "styled-components";

const Title = styled.h3`
  color: ${(props) => props.theme.tertiary};
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 2.75rem;
  margin-bottom: 5vh;
  @media screen and (max-width: 768px) {
    font-size: 2.25rem;
    line-height: 2.25rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
`;
const SectionTitle = ({ title }) => {
  return <Title>{title}</Title>;
};

export default SectionTitle;
