import styled from "styled-components";

export const EHSContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-center;
  align-items: center;
  margin-bottom: 10vh;
`;

export const BannerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  border-radius: 10px;
  background-color: ${(props) => props.theme.other};
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const LeftSection = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.5;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem 0;
`;

export const RightSection = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.45;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h3`
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 2.2rem;
  color: ${(props) => props.theme.tertiary};
  margin-bottom: 4vh;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
`;

export const Description = styled.p`
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.4rem;
  max-width: 40ch;
  color: ${(props) => props.theme.tertiary};
  margin-bottom: 4vh;
  @media screen and (max-width: 480px) {
    font-size: 1.1rem;
    line-height: 1.1rem;
  }
`;

export const TagsContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 60%;
`;
