import styled from "styled-components";

export const CommunityContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20vh;
  flex-direction: column;
`;

export const DetailsContainer = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;
export const LeftSection = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.5;
`;

export const Detail = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: justify;
  color: ${(props) => props.theme.tertiary};
`;

export const RightSection = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.45;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin-bottom: 5vh;
  }
`;
export const Image = styled.img`
  width: 70%;
  height: auto;
`;
