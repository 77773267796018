import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
export const SidebarContainer = styled.div`
  display: none;
  @media screen and (max-width: 900px) {
    position: fixed;
    z-index: 999;
    width: 60%;
    height: 100%;
    background: ${(props) => props.theme.other};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0%;
    right: 0%;
    transition: 0.3s ease;
    opacity: 100%;
    right: ${({ isOpen }) => (isOpen ? "40%" : "100%")};
  }
`;

export const NavLinks = styled(ScrollLink).attrs(() => ({
  activeClass: "active",
}))`
  color: ${(props) => props.theme.tertiary};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Josefin Sans";
  font-style: normal;
  transition: all 0.2s ease-in;
  cursor: pointer;
  width: 100%;
  margin-bottom: 3vh;
  font-weight: 800;
`;

export const NavItems = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;
