import styled from "styled-components";
import { heroBG } from "utils/assets";
import { Link } from "react-scroll";
export const HeroContainer = styled.div`
  height: 100vh;
  max-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 10vh;
  &::before {
    content: "";
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.55) 42.91%,
        #000000 101.72%
      ),
      url(image.png), url(${heroBG});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    z-index: -10;
  }
`;

export const HeroTitle = styled.h3`
  color: ${(props) => props.theme.tertiary};
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3rem;
  margin-bottom: 5vh;
`;

export const HeroDescription = styled.p`
  color: ${(props) => props.theme.tertiary};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 36px;
  text-align: center;
  max-width: 60ch;
  margin-bottom: 5vh;

  @media screen and (max-width: 380px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const CTAButton = styled(Link)`
  color: ${(props) => props.theme.tertiary};
  background-color: ${(props) => props.theme.other};
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 1rem 1.25rem;
  font-weight: 500;
  font-family: "Josefin Sans";
  font-style: normal;
  font-size: 1rem;
  cursor: pointer;
`;
